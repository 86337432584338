import React from 'react'
import { Flex, Heading, Text, Textarea, Input, Button } from '@theme-ui/components'

import ContactWidget from '../../../../../../gatsby-theme-minimal/src/components/General/ContactDetails'
// import cloudinaryImageOptimizer from '../../../helpers/cloudinaryImageOptimizer'
import backgroundStylesGradient from '../../../../../../gatsby-theme-minimal/src/helpers/backgroundStylesGradient'

export default function ContactForm({
  businessData,
  configData,
  options,
  variantName,
  poweredImages,
  locationIndex,
  isMultiLocationSite
}) {
  const { name, street, city, state, zip, phone } = businessData

  const { containerStyles, title, subtitle, text, contactDetails, email, backgroundImages, backgroundGradient } =
    options

  // const [cloudinaryImage, setCloudinaryImage] = useState(undefined)

  const formName = `${name}-${city}`

  const styles = containerStyles ? JSON.parse(containerStyles) : {}
  const variant = variantName ? variantName : 'contactFormV1'

  const backgroundStyles = backgroundImages
    ? backgroundStylesGradient(backgroundImages[locationIndex], backgroundGradient, poweredImages, locationIndex)
    : ''

  return (
    <Flex
      variant={`${variant}.container`}
      sx={{
        // background: backgroundImages
        //   ? `linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5) ), url('${
        //       cloudinaryImage ? cloudinaryImage : ''
        //     }')`
        //   : '',
        backgroundImage: backgroundStyles,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        ...styles
      }}
    >
      {title && (
        <Heading as="h2" variant={`${variant}.title`} className="title">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as="h3" variant={`${variant}.subtitle`} className="subtitle">
          {subtitle}
        </Heading>
      )}
      {text && (
        <Text as="p" variant={`${variant}.text`} className="text">
          {text}
        </Text>
      )}

      {contactDetails && (
        <>
          <ContactWidget
            phone={phone}
            address={{ name, street, city, state, zip }}
            email={email}
            alternativeGoogleMapLink={configData.alternativeGoogleMapLink}
          />
          <br></br>
        </>
      )}

      <Flex
        as="form"
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactform'} //  this can not be dyanmic form names and details must be static      method='POST'
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <Input type="hidden" name="form-name" value={formName ? formName : 'contactform'} />
        <Input
          variant={`${variantName}.name`}
          name="name"
          type="text"
          id="name"
          mb={3}
          placeholder="Name/Company"
          className="inputField name"
          required
        />
        <Input
          variant={`${variantName}.email`}
          type="phone"
          name="phone"
          id="phone"
          mb={3}
          placeholder="Phone"
          className="inputField email"
        />
        <Input
          variant={`${variantName}.email`}
          type="email"
          name="email"
          id="email"
          mb={3}
          placeholder="Email"
          className="inputField email"
          required
        />
        <Input
          variant={`${variantName}.email`}
          type="date"
          name="eventdate"
          id="eventdate"
          mb={3}
          placeholder="Event Date"
          className="inputField email datefield"
        />
        <Input
          variant={`${variantName}.email`}
          type="text"
          name="EventType"
          id="EventType"
          mb={3}
          placeholder="Event Type"
          className="inputField email"
          required
        />
        <Input
          variant={`${variantName}.email`}
          type="number"
          name="numberofguests"
          id="numberofguests"
          mb={3}
          placeholder="Approx. Number Of Guests"
          className="inputField email"
          required
        />

        <Input
          variant={`${variantName}.email`}
          type="text"
          name="howdidyouheaboutus"
          id="howdidyouheaboutus"
          mb={3}
          placeholder="How Did You Hear About Us?"
          className="inputField email"
        />

        <Input
          variant={`${variantName}.email`}
          type="text"
          name="preferredvenue"
          id="preferredvenue"
          mb={3}
          placeholder="Preferred Venue"
          className="inputField email"
          required
        />

        <Textarea
          name="message"
          id="message"
          variant={`${variantName}.message`}
          rows="6"
          mb={3}
          placeholder="Message"
          className="textField message"
          required
        />
        {isMultiLocationSite ? (
          <Input sx={{ display: 'none !important' }} name="location" id="location" value={city} placeholder={city} />
        ) : (
          ''
        )}
        <Button className="button submitBtn" type="submit">
          Submit
        </Button>
      </Flex>
    </Flex>
  )
}
